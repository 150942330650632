import { clone } from '@helpers/utils.js'
import {
  sketchfabLink,
  noWhitespace,
  maxFileSize,
  youtubeLink,
  dimensions,
  regexInput,
  isZipFile,
  required,
  alphaNum,
  between,
  checked,
  maxInt,
  regex,
  image,
  isHex,
  alpha,
  email,
  uuid,
  size,
  url,
  max,
  min,
} from '@helpers/validationRules.js'

export const DEFAULT_URL_EMAIL_MAX = 100
export const DEFAULT_QUERY_LENGTH = 80
export const MAX_FILESIZE_256KB = 262144
export const MAX_FILESIZE_1MB = 1048576
export const MAX_FILESIZE_8MB = 8388608
export const MAX_FILE_SIZE_20GB = 21474836480

export const gameConfig = {
  name: {
    required: true,
    max: 50,
  },
  summary: {
    required: true,
    min: 50,
    max: 250,
  },
  name_id: {
    max: 20,
  },
}

export const gameImagesConfig = {
  header: {
    required: true,
    image: true,
    dimensions: { minWidth: 400, minHeight: 100 },
    maxFileSize: MAX_FILESIZE_256KB,
  },
  icon: {
    required: true,
    image: true,
    dimensions: { minWidth: 64, minHeight: 64 },
    maxFileSize: MAX_FILESIZE_1MB,
  },
  logo: {
    required: true,
    image: true,
    dimensions: { minWidth: 640, minHeight: 360 },
    maxFileSize: MAX_FILESIZE_8MB,
  },
}

export const gameTagConfig = {
  name: {
    required: true,
    max: 30,
  },
  tag: {
    required: true,
    max: 30,
    regex: [/^[^<>\\,"]+$/, 'Tag name cannot contain <>\\," characters'],
  },
}

export const gameThemeConfig = {
  primary: {
    hex: true,
  },
  dark: {
    hex: true,
  },
  light: {
    hex: true,
  },
  success: {
    hex: true,
  },
  warning: {
    hex: true,
  },
  danger: {
    hex: true,
  },
}

export const gameContentConfig = {
  ugc_name: {
    required: true,
    max: 20,
    regex: [/^[a-zA-Z\s]+$/, 'Letters and spaces only'],
  },
  autoban: {
    between: [1, 1000],
  },
  instructions: {
    max: 10000,
  },
}

export const gameLinksConfig = {
  email: {
    required: true,
    email: true,
    max: 100,
  },
  support_url: {
    url: true,
    max: 150,
  },
  url: {
    required: true,
    url: true,
    max: 150,
  },
  urlType: {
    required: true,
    max: 50,
  },
}

export const gameAuthenticationConfig = {
  steam_ticket: {
    alphaNum: true,
    size: 64,
  },
  gog_ticket: {
    regex: [/^[a-zA-Z0-9_-]+$/i, 'Alphanumeric, dashes and underscores only.'],
    size: 43,
  },
  switch_app_id: {
    alphaNum: true,
    min: 16,
    max: 18,
  },
  oculus_rift_id: {
    min: 16,
    max: 17,
  },
  oculus_rift_secret: {
    alphaNum: true,
    size: 32,
  },
  oculus_quest_id: {
    min: 16,
    max: 17,
  },
  oculus_quest_secret: {
    alphaNum: true,
    size: 32,
  },
  apple_bundle_id: {
    regex: [/^[A-Za-z0-9.-]+$/i, 'Alphanumeric, dashes and periods only.'],
    max: 255,
  },
  google_client_id: {
    max: 255,
  },
  google_client_secret: {
    max: 255,
  },
  google_redirect_uri: {
    url: { requireProtocol: true },
    max: 255,
  },
}

export const gameOpenIdConfig = {
  jwk_url: {
    required: true,
    url: true,
    max: 150,
  },
  display_name_claim: {
    max: 80,
    regex: [
      /^[-a-zA-Z0-9-_]+$/i,
      'Alphanumeric characters, underscores and dashes only.',
    ],
  },
  avatar_url_claim: {
    max: 80,
    regex: [
      /^[-a-zA-Z0-9-_]+$/i,
      'Alphanumeric characters, underscores and dashes only.',
    ],
  },
}

export const gameOpenIdTestConfig = {
  jwk_url: {
    required: true,
    url: true,
    max: 150,
  },
  id_token: {
    required: true,
    regex: [
      /^[a-zA-Z0-9_=]{4,}\.[a-zA-Z0-9_=]{4,}\.[a-zA-Z0-9_\-+/=]{4,}$/,
      'The ID token is not valid',
    ],
  },
}

export const gameOAuthConfig = {
  redirecturi: {
    required: true,
    url: { requireProtocol: true },
    max: 150,
  },
}

export const gameEMHConfig = {
  name: {
    required: true,
    min: 3,
    max: 32,
    regex: [/^[^'"`]+$/, 'Quotation marks are not allowed'],
  },
  companyName: {
    max: 30,
    required: true,
  },
  urls: {
    url: { requireProtocol: true },
    required: true,
    max: 255,
  },
  agreementUrl: {
    url: { requireProtocol: true },
    max: 255,
    required: true,
  },
  agreementName: {
    max: 50,
    required: true,
  },
}

export const gameMarketplaceConfig = {
  token_name: {
    required: true,
    max: 10,
    regex: [/^[a-zA-Z\s]+$/, 'Letters and spaces only'],
  },
  maxstock: {
    between: [1, 8388607],
  },
}

export const gameIapConfig = {
  ps4_client_id: {
    uuid: true,
    max: 36,
  },
  ps4_client_secret: {
    max: 16,
  },
  psn_client_id: {
    uuid: true,
    max: 36,
  },
  psn_client_secret: {
    max: 16,
  },
  steam_app_id: {
    regex: [/^\d+$/, 'Field must be a number.'],
    max: 20,
  },
  steam_publisher_key: {
    max: 32,
  },
  egs_sandbox_id: {
    max: 48,
  },
  google_client_id: {
    max: 100,
  },
  google_package_name: {
    max: 100,
  },
  google_service_account_credentials: {
    max: 3000,
  },
  apple_bundle_id: {
    max: 100,
  },
  apple_shared_secret: {
    max: 100,
  },
}

export const webhookNotifyConfig = {
  email: {
    required: true,
    email: true,
    max: DEFAULT_URL_EMAIL_MAX,
  },
}

export const rbmOptionsConfig = {
  ext: {
    required: true,
    max: 10,
  },
  action: {
    required: true,
  },
}

export const teamLeaderConfig = {
  addMemberEmail: {
    required: true,
    email: true,
    max: DEFAULT_URL_EMAIL_MAX,
  },
}

export const teamConfig = {
  real_name: {
    max: 50,
  },
  position: {
    max: 50,
  },
}

export const modBasicsConfig = {
  name: {
    required: true,
    max: 50,
  },
  name_id: {
    max: 50,
  },
  summary: {
    required: true,
    min: 10,
    max: 250,
  },
  homepage_url: {
    url: true,
    max: 150,
  },
  description: {
    max: 50000,
  },
  logo: {
    required: true,
    image: true,
    maxFileSize: MAX_FILESIZE_8MB,
    dimensions: { minWidth: 512, minHeight: 288 },
  },
}

export const modMediaConfig = {
  youtube: {
    youtube: true,
    max: DEFAULT_URL_EMAIL_MAX,
  },
  sketchfab: {
    sketchfab: true,
    max: DEFAULT_URL_EMAIL_MAX,
  },
}

export const modFileConfig = {
  filedata: {
    zip: true,
    maxFileSize: MAX_FILE_SIZE_20GB,
  },
  version: {
    max: 50,
  },
  changelog: {
    max: 50000,
  },
  metadata_blob: {
    max: 50000,
  },
  terms: {
    checked: true,
  },
}

export const modMetadataConfig = {
  metadata_blob: {
    max: 50000,
  },
  metakey: {
    required: true,
    max: 255,
    regex: [
      /^[a-z0-9_-]+$/i,
      'Alphanumeric, dash and underscore characters only.',
    ],
  },
  metavalue: {
    required: true,
    max: 255,
  },
}

export const guideConfig = {
  name: {
    required: true,
    max: 70,
  },
  name_id: {
    max: 70,
  },
  summary: {
    required: true,
    min: 20,
    max: 250,
  },
  description: {
    required: true,
    min: 200,
    max: 150000,
  },
  logo: {
    required: true,
    image: true,
    maxFileSize: MAX_FILESIZE_8MB,
    dimensions: { minWidth: 512, minHeight: 288 },
  },
  tag: {
    max: 30,
  },
  tags: {
    max: 7,
  },
}

export const discussionConfig = {
  comment: {
    required: true,
    max: 5000,
  },
}

export const loginConfig = {
  email: {
    required: true,
    email: true,
    max: DEFAULT_URL_EMAIL_MAX,
  },
  code: {
    noWhitespace: { commaSeparator: false },
    required: true,
    alphaNum: true,
    size: 5,
  },
}

export const mfaConfig = {
  code: {
    noWhitespace: { commaSeparator: false },
    required: true,
    alphaNum: true,
    size: 5,
  },
}

export const userConfig = {
  display_name: {
    required: true,
    regex: [
      /^[-_a-zA-Z0-9]+$/i,
      'Alphanumeric characters, dashes and underscores only.',
    ],
    max: 20,
  },
  name_id: {
    max: 20,
    regex: [/^[-a-zA-Z0-9]+$/i, 'Alphanumeric characters and dashes only.'],
  },
  email: {
    required: true,
    email: true,
    max: 100,
  },
}

export const socialConfig = {
  avatar: {
    required: true,
    image: true,
    maxFileSize: MAX_FILESIZE_8MB,
    dimensions: { minWidth: 50, minHeight: 50 },
  },
}

export const usernameConfig = {
  display_name: {
    required: true,
    max: 20,
    regex: [
      /^[-_a-zA-Z0-9]+$/i,
      'Alphanumeric characters, dashes and underscores only.',
    ],
  },
}

export const oauthConfig = {
  name: {
    required: true,
    max: 255,
  },
}

export const tokenConfig = {
  name: {
    required: true,
    max: 255,
  },
}

export const reportConfig = {
  name: {
    max: 100,
  },
  email: {
    max: DEFAULT_URL_EMAIL_MAX,
  },
  address: {
    max: 250,
  },
  urls: {
    max: 2560,
  },
  summary: {
    required: true,
    max: 10000,
  },
}

export const messagingConfig = {
  message: {
    max: 50000,
  },
}

export const inlineMediaConfig = {
  folder: {
    required: true,
    max: 8,
    regex: [/^[-a-zA-Z0-9]+$/i, 'Alphanumeric characters and dashes only.'],
  },
  image: {
    image: true,
    maxFileSize: MAX_FILESIZE_8MB,
  },
}

export const contactConfig = {
  email: {
    required: true,
    email: true,
    max: 100,
  },
  subject: {
    max: 100,
  },
  message: {
    required: true,
    min: 10,
    max: 50000,
  },
}

export const requestDemoConfig = {
  name: {
    required: true,
    max: 30,
  },
  gameName: {
    required: true,
    max: 50,
  },
  email: {
    required: true,
    email: true,
    max: contactConfig.email.max,
  },
}

export const agreementConfig = {
  agreement_id: {
    required: true,
  },
  changelog: {
    required: true,
    max: 50000,
  },
  description: {
    required: true,
    max: 500000,
  },
}

export const templateConfig = {
  name: {
    required: true,
    max: 50,
  },
  summary: {
    required: true,
    min: 50,
    max: 250,
  },
}

export const skuPackConfig = {
  amount: {
    required: true,
    between: [1, 100000],
  },
  price: {
    required: true,
    between: [0.01, 1000],
  },
  sku: {
    required: true,
    max: 100,
  },
}

export const statusConfig = {
  reason: {
    required: true,
    max: 1000,
  },
}

export const mergeConfig = {
  searchFrom: {
    required: true,
  },
  searchTo: {
    required: true,
  },
}

export const kybConfig = {
  firstname: {
    required: true,
    max: 30,
  },
  lastname: {
    required: true,
    max: 30,
  },
  email: {
    required: true,
    email: true,
    max: 100,
  },
  jurisdiction: {
    required: true,
  },
  contact: {
    required: true,
    regex: [
      /^\+[1-9]\d{1,14}$/,
      'Must be a valid phone number including an international dial code',
    ],
    max: 16,
  },
  company: {
    max: 30,
  },
}

export const registerInterestConfig = clone(kybConfig)
delete registerInterestConfig.jurisdiction
delete registerInterestConfig.contact.required

export const refundConfig = {
  reason: {
    required: true,
  },
}

export const parnerProgramCriteriaConfig = {
  max_dmca: {
    max: 50,
  },
  max_reports: {
    max: 100,
  },
  min_mods: {
    max: 50,
  },
  min_ratings: {
    max: 100,
  },
  min_subscriptions: {
    max: 1000,
  },
}

export const kycTermsConfig = {
  monTermsAgreed: {
    checked: true,
  },
  termsAgreed: {
    checked: true,
  },
}

export const monTermsConfig = {
  monTermsAgreed: {
    checked: true,
  },
}

export const partnerProgramApplyConfig = {
  reason: {
    required: true,
    min: 20,
    max: 255,
  },
  termsAgreed: {
    checked: true,
  },
}

export const partnerProgramApplicationToggleConfig = {
  reason: {
    required: true,
    max: 255,
  },
}

export const rbmNameConfig = {
  name: {
    max: 50,
  },
}

export const moderationToggleConfig = {
  reason: {
    required: true,
    max: 255,
  },
}

export const convertConfig = {
  tokens: {
    between: [10, 1000000],
  },
  usd: {
    between: [0.04, 1000000],
  },
}

export const studioSSOConfig = {
  icon: {
    required: true,
    image: true,
    dimensions: { minWidth: 64, minHeight: 64 },
    maxFileSize: MAX_FILESIZE_1MB,
  },
  name: {
    required: true,
    max: 50,
  },
  authorize_url: {
    required: true,
    max: 500,
    url: { requireProtocol: true, allowQueryParams: false },
  },
  prompt_user_login: {
    required: true,
  },
  token_url: {
    required: true,
    max: 500,
    url: { requireProtocol: true, allowQueryParams: false },
  },
  user_info_url: {
    max: 500,
    url: { requireProtocol: true, allowQueryParams: false },
  },
  scopes: {
    max: 20,
    noWhitespace: { commaSeparator: true },
  },
  portal_id_claim: {
    max: 20,
    regex: [
      /^[-a-zA-Z0-9-_]+$/i,
      'Alphanumeric characters, underscores and dashes only.',
    ],
  },
  display_name_claim: {
    max: 80,
    regex: [
      /^[-a-zA-Z0-9-_]+$/i,
      'Alphanumeric characters, underscores and dashes only.',
    ],
  },
  client_id: {
    required: true,
    max: 255,
  },
  client_secret: {
    required: true,
    max: 255,
  },
}

export const fundWalletConfig = {
  amount: {
    required: true,
    maxInt: 100000,
    max: 8,
    regex: [
      /^\d+(\.\d{0,2})?$/i,
      'Field must be a number with maximum 2 decimal places',
    ],
  },
}

export const filterTransactionConfig = {
  id: {
    max: 20,
    regex: [/^\d+$/, 'Field must be a number.'],
  },
}

export function getValidationFunctions(config) {
  const validationFunctions = {}

  for (let [field, validations] of Object.entries(config)) {
    validationFunctions[field] = Object.entries(validations).map(
      ([key, value]) => {
        if (key === 'required') return required
        if (key === 'checked') return checked
        if (key === 'min') return min(value)
        if (key === 'max') return max(value)
        if (key === 'maxInt') return maxInt(value)
        if (key === 'zip') return isZipFile
        if (key === 'image') return image
        if (key === 'dimensions') return dimensions(value)
        if (key === 'maxFileSize') return maxFileSize(value)
        if (key === 'between') return between(...value)
        if (key === 'email') return email
        if (key === 'url') return url(value)
        if (key === 'uuid') return uuid
        if (key === 'hex') return isHex
        if (key === 'alpha') return alpha
        if (key === 'alphaNum') return alphaNum
        if (key === 'size') return size(value)
        if (key === 'youtube') return youtubeLink
        if (key === 'sketchfab') return sketchfabLink
        if (key === 'regex') return regex(...value)
        if (key === 'custom') return value
        if (key === 'regexInput') return regexInput
        if (key === 'noWhitespace') return noWhitespace(value)
        throw new Error('Unrecognised validation function')
      }
    )
  }

  config.validation = validationFunctions
}

const _configs = [
  gameConfig,
  gameImagesConfig,
  gameTagConfig,
  gameThemeConfig,
  gameContentConfig,
  gameLinksConfig,
  gameAuthenticationConfig,
  fundWalletConfig,
  gameOpenIdConfig,
  gameOpenIdTestConfig,
  gameOAuthConfig,
  gameEMHConfig,
  gameMarketplaceConfig,
  gameIapConfig,
  webhookNotifyConfig,
  rbmOptionsConfig,
  teamLeaderConfig,
  teamConfig,
  modBasicsConfig,
  modMediaConfig,
  modFileConfig,
  modMetadataConfig,
  guideConfig,
  discussionConfig,
  loginConfig,
  userConfig,
  socialConfig,
  usernameConfig,
  oauthConfig,
  tokenConfig,
  reportConfig,
  messagingConfig,
  inlineMediaConfig,
  contactConfig,
  requestDemoConfig,
  agreementConfig,
  templateConfig,
  skuPackConfig,
  statusConfig,
  mergeConfig,
  kybConfig,
  refundConfig,
  kycTermsConfig,
  monTermsConfig,
  partnerProgramApplyConfig,
  partnerProgramApplicationToggleConfig,
  rbmNameConfig,
  moderationToggleConfig,
  studioSSOConfig,
  registerInterestConfig,
  filterTransactionConfig,
  mfaConfig,
]

_configs.forEach((config) => getValidationFunctions(config))
