import { WIDGET_PREFIX_ROUTE } from '@config/routeNames.js'
import { scrollTo, scrollIntoView } from 'scroll-js'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { countries } from '@config/countries.js'
import lodashThrottle from 'lodash/throttle'
import difference from 'lodash/difference'
import { Duration, DateTime } from 'luxon'
import { getCurrentInstance } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import isURL from 'validator/lib/isURL'
import { customAlphabet } from 'nanoid'
import readingTime from 'reading-time'
import equal from 'lodash/isEqual'
import filesize from 'filesize'
import {
  amchartsContinents as continents,
  subContinents,
} from '@config/continents.js'
import get from 'lodash/get'
import cookie from 'cookie'
import qs from 'qs'
import {
  ONLINE_USER_TIMEOUT_SECONDS,
  PHP_UNIXTIME_LEN,
  IMAGE_CROP_SIZES,
  LOCALE_LANGUAGE,
  HEADER_HEIGHT,
} from '@config'

// Dynamic import to avoid circular dependency
let authStore
import('@stores/authStore.js').then((data) => {
  authStore = data.default()
})

export const MS_SECOND = 1000
export const MS_MINUTE = 60 * MS_SECOND
export const MS_HOUR = 60 * MS_MINUTE
export const MS_DAY = 24 * MS_HOUR
export const MS_YEAR = 365 * MS_DAY

export const isClient = typeof window !== 'undefined'
export const defaultDocument = isClient ? window.document : undefined

/* istanbul ignore next */
export function isEqual(value, other) {
  return equal(value, other)
}

export function arrayDiff(array, values) {
  return difference(array, values)
}

export function deleteCookie(name) {
  if (document?.cookie) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}

export function parseCookie(cookies) {
  return cookie.parse(cookies)
}

export function genRanId() {
  const nanoid = customAlphabet(
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    21
  )

  return nanoid()
}

export function genHtmlId() {
  const instance = getCurrentInstance()
  return `input${instance?.uid || genRanId()}`
}

export function stringProper(text) {
  return text
    .toString()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function callOrReturn(input) {
  if (!input) {
    return
  } else if (isFunction(input)) {
    return input()
  } else {
    return input
  }
}

export function removeDupes(array) {
  return [...new Set(array)].filter((t) => t)
}

export function isFunction(val) {
  return toString.call(val) === '[object Function]'
}

export function isArray(val) {
  return toString.call(val) === '[object Array]'
}

export function isDate(val) {
  return toString.call(val) === '[object Date]'
}

export function isObj(val) {
  return toString.call(val) === '[object Object]'
}

export function isString(val) {
  return toString.call(val) === '[object String]'
}

export function isRegex(val) {
  return toString.call(val) === '[object RegExp]'
}

export function isFile(val) {
  return toString.call(val) === '[object File]'
}

export function isBoolean(val) {
  return (
    toString.call(val) === '[object Boolean]' ||
    val === 1 ||
    val === 0 ||
    val === '0' ||
    val === '1'
  )
}

export function isNumber(val) {
  return typeof val === 'number'
}

export function isNullOrUndefined(val) {
  return [null, undefined].includes(val)
}

export function isObjEmpty(obj) {
  return isObj(obj) && Object.keys(obj).length === 0
}

export function isEven(index) {
  return index % 2 === 0
}

export function arrayWrap(item) {
  if (isArray(item)) {
    return item
  }

  return [item]
}

export function clone(data) {
  return cloneDeep(data)
}

export function addProtocol(url) {
  if (!url) return
  if (isURL(url, { require_protocol: true })) {
    return url
  } else if (isURL(url)) {
    return `https://${url}`
  }

  return url
}

export function removeProtocol(url) {
  if (!url) return

  if (!isURL(url, { require_protocol: true })) return url

  return url.replace(/^.+?:\/\//, '')
}

export function getHashObj() {
  return Object.fromEntries(
    location.hash
      .replace('#', '')
      .split('&')
      .filter((h) => h)
      .map((hash) => hash.split('='))
  )
}

export function pad(input, count, char = '0') {
  if (input.toString().length >= count) return input.toString()

  const start = new Array(count).fill(char).join('')
  return (start + input).slice(-count)
}

export function queryString(query) {
  if (!isObj(query) || !query) {
    return ''
  }
  return `?${qs.stringify(query)}`
}

export function isWidget(path) {
  if (!path) path = new URL(window.location.href).pathname.toLowerCase()

  if (!path.includes(`/${WIDGET_PREFIX_ROUTE}`)) return false

  if (
    path.includes(`/m/${WIDGET_PREFIX_ROUTE}`) ||
    path.includes(`/r/${WIDGET_PREFIX_ROUTE}`)
  ) {
    if (path.includes(`/${WIDGET_PREFIX_ROUTE}/${WIDGET_PREFIX_ROUTE}`)) {
      return true
    } else {
      return path
        .replace(`/m/${WIDGET_PREFIX_ROUTE}`, '')
        .replace(`/r/${WIDGET_PREFIX_ROUTE}`, '')
        .includes(`/${WIDGET_PREFIX_ROUTE}`)
    }
  }

  return true
}

export function unixTime(date) {
  return isDate(date)
    ? parseInt((date.getTime() / 1000).toFixed(0))
    : date instanceof DateTime
      ? date.toUnixInteger()
      : null
}

function _formatDuration(value, isShort, short, long) {
  const prefix = value < 0 ? 'in ' : ''
  const val = Math.abs(value)
  let timeAmount = isShort ? short : ` ${long}`
  if (!isShort && val !== 1) {
    timeAmount += 's'
  }

  return `${prefix}${val}${timeAmount}`
}

export function timeSince(timestamp, { short = true } = {}) {
  if (!timestamp) return ''

  const compareTime =
    getNumberLength(timestamp) <= PHP_UNIXTIME_LEN
      ? secsToMs(timestamp)
      : timestamp

  const diff = Date.now() - compareTime
  const { years, days, hours, minutes, seconds } = Duration.fromMillis(diff)
    .shiftTo('years', 'days', 'hours', 'minutes', 'seconds', 'milliseconds')
    .toObject()

  if (Math.abs(years) > 0) {
    return _formatDuration(years, short, 'y', 'year')
  } else if (Math.abs(days) > 0) {
    return _formatDuration(days, short, 'd', 'day')
  } else if (Math.abs(hours) > 0) {
    return _formatDuration(hours, short, 'h', 'hour')
  } else if (Math.abs(minutes) > 0) {
    return _formatDuration(minutes, short, 'm', 'minute')
  } else {
    return _formatDuration(seconds, short, 's', 'second')
  }
}

export function timeRemaining(timeStamp) {
  if (!timeStamp) return ''

  const dateTime = Math.max(timeStamp - Date.now(), 0)
  const { days, hours, minutes, seconds } = Duration.fromMillis(
    dateTime
  ).shiftTo('days', 'hours', 'minutes', 'seconds')

  return `${days > 999 ? '+999' : days}:${pad(hours, 2)}:${pad(
    minutes,
    2
  )}:${pad(Math.ceil(seconds), 2)}`
}

export function isOnlineUser(unixTime) {
  const current = parseInt(Date.now().toFixed(0))
  const lastOnline = secsToMs(unixTime)

  return lastOnline > current - secsToMs(ONLINE_USER_TIMEOUT_SECONDS)
}

export function humanDateFormat(timestamp, dateTime = false) {
  if (!timestamp) {
    return ''
  }
  if (!isNumber(timestamp)) {
    return ''
  }

  let dateObject

  if (getNumberLength(timestamp) <= PHP_UNIXTIME_LEN) {
    dateObject = DateTime.fromSeconds(timestamp).toJSDate()
  } else {
    dateObject = DateTime.fromMillis(timestamp).toJSDate()
  }

  return dateTime
    ? dateObject.toLocaleTimeString(undefined, {
        timeZone: authStore?.user?.value?.info?.timezone || undefined,
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
    : dateObject.toLocaleString(undefined, {
        timeZone: authStore?.user?.value?.info?.timezone || undefined,
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
}

export function humanDateTimeFormat(timestamp) {
  return humanDateFormat(timestamp, true)
}

export function humanNumberFormat(number) {
  if (!isNumber(number)) {
    return 0
  }

  return number.toLocaleString(LOCALE_LANGUAGE)
}

export function humanFilesize(bytes) {
  return filesize(bytes || 0)
}

export function getNumberLength(number) {
  return number.toString().length
}

export function proper(text) {
  if (!text || !isString(text)) {
    return
  }

  return text
    .split(' ')
    .map((word) => {
      const firstLetter = word.slice(0, 1)
      const rest = word.slice(1)
      return `${firstLetter.toUpperCase()}${rest.toLowerCase()}`
    })
    .join(' ')
}

export function flatten(data) {
  const newObj = {}
  Object.keys(data).forEach((key) => {
    if (data[key] && typeof data[key] === 'object') {
      Object.keys(data[key]).forEach((innerKey) => {
        newObj[innerKey] = data[key][innerKey]
      })
    } else {
      newObj[key] = data[key]
    }
  })

  return newObj
}

/* istanbul ignore next */
export function getReadingTime(string) {
  return readingTime(string)
}

/* istanbul ignore next */
export function throttle(fn, duration) {
  return lodashThrottle(fn, duration, {
    leading: false,
  })
}

export function objectGet(object, key) {
  return get(object, key)
}

export function secsToMs(secs) {
  return secs * MS_SECOND
}

export function msToSecs(ms) {
  return parseInt((ms / MS_SECOND).toFixed(0))
}

export function youtubeParser(url) {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length == 11 ? match[7] : false
}

export function numberFormatter(num, places = 0) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value)

  if (num >= 1e9 && places === 0) {
    places = 3
  } else if (num >= 1e6 && places === 0) {
    places = 1
  }

  return item
    ? (num / item.value).toFixed(places).replace(rx, '$1') + item.symbol
    : '0'
}

/* istanbul ignore next */
export function asyncSleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

// Image crop using url format crop_320x180
export function getCropImageUrl({ originalUrl, size, type = 'crop' }) {
  // throws type error if not a valid url
  new URL(originalUrl)
  if (!IMAGE_CROP_SIZES.includes(size)) {
    throw new Error('Crop size not allowed.')
  }
  return originalUrl.replace(
    /(crop|thumb|image)_([0-9]+x[0-9]+)/,
    `${type}_${size}`
  )
}

export function separateCamelCase(str, separator = '-') {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? separator : ''}${letter.toLowerCase()}`
        : letter
    })
    .join('')
}

export function toCamelCase(str, separator = '_') {
  return str
    .split(separator)
    .map((word, idx) =>
      idx === 0 ? word : word[0].toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('')
}

export function validNameId(value) {
  return value
    .replace(/ /g, '-')
    .replace(/[^-a-zA-Z0-9]+/g, '')
    .replace(/[-]+/g, '-')
    .replace(/^-(.*)/g, '$1')
    .replace(/(.*)-$/g, '$1')
    .toLowerCase()
}

export function areBitsSet(value, mask, returnValue = false) {
  const result = value & mask
  return returnValue ? result : result >= 1
}

export function setBits(value, mask) {
  return value | mask
}

export function unsetBits(value, mask) {
  return value & ~mask
}

export function generateSortQuery(direction, sortKey) {
  return direction === 'desc' ? `-${sortKey}` : sortKey
}

export function getOptionText({ options, value }) {
  return options.find((o) => o.value === value)?.text
}

export function apiErrorsToArray(error) {
  if (isObj(error) && !isObjEmpty(error)) {
    let arr = []
    Object.values(error).forEach((v) => {
      if (isObj(v) && !isObjEmpty(v)) {
        Object.values(v).forEach((x) => {
          if (isString(x)) arr.push(x)
        })
      } else {
        arr.push(v)
      }
    })
    return arr
  } else if (isString(error)) {
    return [error]
  } else {
    return []
  }
}

export function getQueryObj() {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
}

export function getProps(item, card) {
  const itemProps = {}
  card.footerProps &&
    isArray(card.footerProps) &&
    card.footerProps.forEach((element) => {
      itemProps[toCamelCase(element)] = item[element]
    })

  return itemProps
}

export function isNavigationInSameBrowseView(to, from) {
  return to.meta?.browse && to.name === from.name
}

export function isSameRouteChild(to, from) {
  return to.meta?.children?.includes(from.name) || from.fullPath === to.fullPath
}

export function handleScroll(to, from) {
  // handles useBrowse push & widget
  if (
    isNavigationInSameBrowseView(to, from) ||
    to.hash ||
    isWidget(to.fullPath.toLowerCase())
  ) {
    return
  }

  const container = defaultDocument.getElementById(SCROLL_TO_ID)

  if (container) {
    const sameChild = isSameRouteChild(to, from)
    scrollTo(container, { top: 0, duration: sameChild ? undefined : 0 })
  }
}

export function scrollToElement(id, timeout = 0) {
  const container = defaultDocument.getElementById(SCROLL_TO_ID)
  const target = defaultDocument.getElementById(id)

  if (target) {
    setTimeout(() => {
      scrollIntoView(target, container, {
        duration: 300,
        easing: 'ease-in-out',
      })
    }, timeout)
  }
}

export function scrollToElementOffset(
  idOrEl,
  offset = -HEADER_HEIGHT - 20,
  containerId = undefined
) {
  const container = defaultDocument.getElementById(containerId || SCROLL_TO_ID)

  let target
  if (isString(idOrEl)) {
    target = defaultDocument.getElementById(idOrEl)
  } else {
    target = idOrEl
  }

  if (target) {
    const rect = target.getBoundingClientRect()
    const elementTop = container.scrollTop + rect.top
    scrollTo(container, {
      top: elementTop + offset,
      duration: 300,
      easing: 'ease-in-out',
    })
  }
}

export function getQueryFromPath(url) {
  return url.includes('?') ? qs.parse(url.split('?').pop()) : {}
}

export function hasDynamicBreadcrumbName(name) {
  return name.startsWith(':')
}

export function apostrophes(val) {
  if (!isString(val)) return val
  return val.slice(-1) === 's' ? val : `${val}'s`
}

export function getOtherURLType(url) {
  if (url.match(/steampowered/i)) return 'Steam'
  if (url.match(/xbox|microsoft|windows/i)) return 'Xbox'
  if (url.match(/sony|playstation/i)) return 'PlayStation'
  if (url.match(/nintendo|switch/i)) return 'Switch'
  if (url.match(/oculus|rift|quest/i)) return 'Oculus'
  if (url.match(/google/i)) return 'Android'
  if (url.match(/apple/i)) return 'iOS'
  if (url.match(/discord/i)) return 'Discord'
  if (url.match(/itch\.io/i)) return 'itch.io'
  if (url.match(/gog/i)) return 'GOG.com'
  if (url.match(/facebook/i)) return 'Facebook'
  if (url.match(/twitter/i)) return 'Twitter'
  if (url.match(/youtube/i)) return 'Youtube'
  if (url.match(/twitch/i)) return 'Twitch'
  if (url.match(/epicgames/i)) return 'Epic Games'
  if (url.match(/instagram/i)) return 'Instagram'
  if (url.match(/reddit/i)) return 'Reddit'
  if (url.match(/indiedb/i)) return 'IndieDB'
  if (url.match(/moddb/i)) return 'ModDB'
  if (url.match(/gamejolt/i)) return 'Gamejolt'
  return null
}

export function startsWithVowel(val) {
  if (!isString(val) || val.length === 0) return false
  return 'aeiou'.includes(val[0].toLowerCase())
}

export function arrayMove(array, from, to) {
  if (from > array.length - 1 || from < 0 || to < 0)
    throw new Error('index out of bounds')

  array.splice(to, 0, array.splice(from, 1)[0])
  return array
}

export function getCountryFlag(countryCode) {
  const validEmoji = /\p{Emoji}/u

  let result = ''
  const codePoints = countryCode
    ?.toUpperCase()
    ?.split('')
    ?.map((char) => 127397 + char.charCodeAt())

  if (validEmoji.test(String.fromCodePoint(...codePoints))) {
    result = String.fromCodePoint(...codePoints)
  }

  return result
}

export function getCountryName(code) {
  const country = countries.find((item) => item.value === code?.toUpperCase())
  return country?.text || code?.toUpperCase()
}

export function getCountryCode(name) {
  const country = countries.find(
    (item) =>
      item.text.toLowerCase() === name.toLowerCase() ||
      item.alias?.toLowerCase() === name.toLowerCase()
  )
  return country?.value
}

// supports code or name
export function getContinentFromCountry(val) {
  const code = val.length > 2 ? getCountryCode(val) : val
  return continents.find((x) => x.countries.includes(code))
}

export function getSubContinentFromCountry(val) {
  const code = val.length > 2 ? getCountryCode(val) : val
  return subContinents.find((x) => x.countries.includes(code))
}

export function isCountry(code) {
  return countries.some((x) => x.value === code)
}

export function isContinent(code) {
  return continents.some((x) => x.value === code)
}

export function isSubContinent(code) {
  return subContinents.some((x) => x.value === code)
}

export function getMobileOS() {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return 'winphone'
  } else if (/android/i.test(userAgent)) {
    return 'android'
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }
  return ''
}

// Tells us the percentage of gained value that has been kept from the new total
export function getRetentionPercentage(oldVal, newVal, gained) {
  return Math.round(((newVal - gained) / oldVal) * 100)
}

export function getPercentageComparison(val1, val2) {
  return Math.round(((val1 - val2) / val2) * 100)
}

// returns the appropriate index for a new item in a sorted array
export function orderedArraySearch(array, newValue, direction = 'asc') {
  let i = 1
  switch (direction) {
    case 'asc':
      if (array[0] >= newValue) {
        return 0
      }
      while (
        i < array.length &&
        !(array[i] > newValue && array[i - 1] <= newValue)
      ) {
        i = i + 1
      }
      return i
    case 'desc':
      if (array[0] <= newValue) {
        return 0
      }
      while (
        i < array.length &&
        !(array[i] < newValue && array[i - 1] >= newValue)
      ) {
        i = i + 1
      }
      return i
  }
}

export function capitalisedFirstLetter(word) {
  if (!word) return ''
  return `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`
}

export function xAxisDateFormatter(val) {
  const dateArray = new Date(val).toDateString()?.split(' ')
  return `${dateArray[1]} ${dateArray[2]}`
}

export function yAxisFormatter(val, round = false, decimal) {
  const number = round ? Math.round(val * 100) / 100 : val
  return numberFormatter(number, decimal)
}

export function yValueFormatter(val, round = false) {
  const number = round ? Math.round(val * 100) / 100 : val
  return humanNumberFormat(number)
}

export function norm(value, min, max, scale = 1) {
  // rounded to 5 decimal places max
  return Math.round(((value - min) / (max - min)) * scale * 100000) / 100000
}

export function getRatio(val1, val2, single = false) {
  let result1 = val1
  let result2 = val2

  if (isNullOrUndefined(result1) || isNullOrUndefined(result2)) {
    return 'N/A'
  }

  for (let i = result2; i > 1; i--) {
    if (!(result1 % i) && !(result2 % i)) {
      result1 = result1 / i
      result2 = result2 / i
    }
  }

  return single
    ? `${humanNumberFormat(Math.floor(result1 / result2))} : 1`
    : `${humanNumberFormat(result1)} : ${humanNumberFormat(result2)}`
}

export function getDaysBetweenTwoDates(startDate, endDate) {
  const difference = endDate.getTime() - startDate.getTime()
  return Math.ceil(difference / (1000 * 3600 * 24))
}

export function getSiblingIndex(elem) {
  let index = -1
  do {
    index++
    elem = elem.previousElementSibling
  } while (elem)
  return index
}

const cleanElem = document.createElement('div') // here so it's not recreated every time the function is called
export function cleanHTMLEntities(text) {
  cleanElem.innerHTML = text
  return cleanElem.textContent
}

export function cleanFilename(filename) {
  const match = filename.match(/(.+)(\.[^.]*$)/)
  if (!match) return filename

  const ext = match[2]
  let name = match[1]
  name = name.replace(/\+/g, '_')
  name = name.replace(/[^0-9a-z-_+.]/gi, '')
  name = name.replace(/[_]+/g, '_')
  name = name.replace(/^_+/, '')
  name = name.replace(/_+$/, '')
  name = name.toLowerCase()

  return `${name}${ext}`
}

export function clamp(value, min, max) {
  return Math.max(min, Math.min(value, max))
}

export function findIndices(array, search) {
  const indices = []
  array.forEach((element, i) => {
    if (element === search) {
      indices.push(i)
    }
  })
  return indices
}

export function durationFormatter(mins) {
  if (!mins) return '0 mins'
  return Duration.fromMillis(mins * 1000 * 60)
    .toFormat('hh:mm')
    .replace(':', ' hrs ')
    .concat(' mins')
}
