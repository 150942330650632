<template>
  <div
    v-if="activeBanners.length"
    class="tw-flex tw-flex-col tw-my-4 tw-w-full tw-space-y-4"
  >
    <inline-message
      v-for="(item, index) in activeBanners"
      :key="index"
      :has-close="item.noDismiss"
      :has-icon="false"
      :info="item.isInfo"
      :success="item.isSuccess"
      :warning="item.isWarning"
      :danger="item.isError"
      @close:message="toggle(item.name)"
    >
      <div
        class="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start tw-gap-4"
      >
        <div>
          <panel-header
            v-if="item.title"
            small
            :text="item.title"
            :status="item.status"
            :success="item.status && item.isSuccess"
            :info="item.status && item.isError"
            :warning="item.status && item.isError"
            :danger="item.status && item.isError"
          />
          <div class="tw-content tw-w-full">
            <component :is="item.customComponent" v-if="item.customComponent" />

            {{ item.text }}

            <router-link v-if="item.cta" :to="item.cta.to" class="tw-underline">
              {{ item.cta.text }}
            </router-link>
          </div>
        </div>

        <base-link
          v-if="
            MODIO_ENV !== 'test' &&
            item.status &&
            item.status.toLowerCase() !== 'live' &&
            gameAdmin
          "
          :to="{ name: GAME_ADMIN_SETTINGS_ROUTE }"
          class="tw-w-full sm:tw-w-fit"
        >
          <base-button full-width-at="sm">Resolve</base-button>
        </base-link>
      </div>
    </inline-message>
  </div>
</template>

<script>
import { useBanners, useRoute, usePermissions } from '@composables'
import InlineMessage from '@components/Message/InlineMessage.vue'
import { GAME_ADMIN_SETTINGS_ROUTE } from '@config/routeNames.js'
import PanelHeader from '@components/PanelHeader.vue'
import TestEnv from '@components/Error/TestEnv.vue'
import Preview from '@components/Game/Preview.vue'
import { MODIO_ENV } from '@config'
import { gameStore } from '@stores'
import { computed } from 'vue'

export default {
  components: {
    InlineMessage,
    PanelHeader,
    Preview,
    TestEnv,
  },
  setup() {
    const { hasPermissionByNameId } = usePermissions()
    const { activeBanners, toggle } = useBanners()
    const { getPublicGame } = gameStore()
    const { getGameId } = useRoute()

    const game = getPublicGame(getGameId().value)

    const gameAdmin = computed(() =>
      hasPermissionByNameId({
        resource: 'game',
        nameId: game.value?.name_id,
        scope: 'admin',
      })
    )

    return {
      GAME_ADMIN_SETTINGS_ROUTE,
      activeBanners,
      MODIO_ENV,
      gameAdmin,
      toggle,
      game,
    }
  },
}
</script>
