import { GAME_PLATFORM_LABELS } from '@config/options.js'

export const defaultMapConfig = {
  enableRegionInteractivity: true,
  height: 540,
}

export const REGION_TYPES = {
  CONTINENTS: 'continents',
  SUB_CONTINENTS: 'subcontinents',
  COUNTRIES: 'countries',
}

export const MAP_DROPDOWN_SELECTIONS = {
  // Not used currently for amcharts
  // SUB_CONTINENTS: { value: REGION_TYPES.SUB_CONTINENTS, text: 'Subcontinent' },
  COUNTRIES: { value: REGION_TYPES.COUNTRIES, text: 'Countries' },
  CONTINENTS: { value: REGION_TYPES.CONTINENTS, text: 'Continents' },
}

// Keep the value in ascending order except CUSTOM
export const TIME_PERIOD_OPTIONS = {
  CUSTOM: { value: -1, text: 'Custom period' },
  LIVE: { value: 1000, text: 'Live' },
  LIFETIME: { value: 999, text: 'Lifetime' },
  TWENTYFOUR_MONTHS: { value: 8, text: '2 years', months: 24 },
  EIGHTEEN_MONTHS: { value: 7, text: '18 months', months: 18 },
  FIFTEEN_MONTHS: { value: 6, text: '15 months', months: 15 },
  TWELVE_MONTHS: { value: 5, text: '1 year', months: 12 },
  SIX_MONTHS: { value: 4, text: '6 months', months: 6 },
  THREE_MONTHS: { value: 3, text: '3 months', months: 3 },
  ONE_MONTH: { value: 2, text: '1 month', months: 1 },
  ONE_WEEK: { value: 1, text: '1 week', weeks: 1 },
}

export const COMPARISON_OPTIONS = {
  ACTIVE_USERS: { value: 1, text: 'Active users' },
  CREATOR_JOINED: { value: 2, text: 'Creators joined' },
  DOWNLOADS: { value: 3, text: 'Downloads' },
  MODS_ADDED: { value: 4, text: 'Mods added' },
  SUBSCRIPTIONS: { value: 5, text: 'Subscriptions' },
}

export const TRENDS_TYPE_OPTIONS = {
  ACTIVE_USERS_FULL: 'users-full',
  ACTIVE_USERS_AUTH: 'users-auth',
  ACTIVE_USERS_AUTH_ENGAGED: 'users-eng',
  DOWNLOADS: 'downloads',
}

export const GROUP_BY_OPTIONS = {
  LOCATION: 'location',
  PLATFORM: 'platform',
  RESOURCE: 'resourcetype',
  REPORT: 'reporttype',
  EVENT: 'eventtype',
  PERMISSION_UPDATE: 'permissionupdatetype',
}

export const BAR_USERS_SELECTION_OPTIONS = {
  ALL_VISITS: 'All visits',
  AUTHENTICATED: 'Authenticated',
  INTERACTIONS: 'Interactions',
}

export const TOTAL_USER_RESPONSE_TYPES = {
  FULL: 'total_active_user_full',
  AUTH: 'total_active_user_auth',
  INTERACTION: 'total_active_user_interaction',
}

export const RECORD_TYPES = {
  SESSION: 'session',
  DAILY: 'daily',
  TOTAL: 'total',
  MONTHLY: 'monthly',
  TRENDING: 'trending',
}

export const STANDARD_EVENT_TYPES = {
  MOD_DOWNLOAD: 'mod_download',
  MOD_SUBSCRIBE: 'mod_subscribe_movement',
  GAME_SUBSCRIBE: 'game_subscribe_movement',
  MOD_ADDED: 'mod_added',
  MOD_UPDATED: 'mod_updated',
  MOD_FILE_ADDED: 'mod_file_added',
  MOD_COMMENT: 'mod_comment',
  GAME_ADDED: 'game_added',
  GAME_UPDATED: 'game_updated',
  GUIDE_ADDED: 'guide_added',
  GUIDE_UPDATED: 'guide_updated',
  GUIDE_COMMENT: 'guide_comment',
  USER_JOINED: 'user_joined',
  CREATOR_JOINED: 'creator_joined',
  CREATOR_DOWNLOAD: 'creator_download',
  API_KEY: 'api_key',
  BANDWIDTH: 'game_bandwidth',
  FILE_ADDED: 'file_added',
  REPORT_OPENED: 'report_opened',
  REPORT_CLOSED: 'report_closed',
  PERMISSION_CHANGE: 'permission_change',
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
  TRANSACTION_NET: 'transaction_net',
  TRANSACTION_GROSS: 'transaction_gross',
  TRANSACTION_FX_FEE: 'transaction_fx_fee',
  TRANSACTION_GATEWAY_FEE: 'transaction_gateway_fee',
  TRANSACTION_TOKENS: 'transaction_tokens',
  TRANSACTION_UNCLEARED_GAME: 'transaction_uncleared_game',
  TRANSACTION_UNCLEARED_MOD: 'transaction_uncleared_mod',
  CCU_SESSION_PLAYTIME: 'ccu_session_playtime',
  CCU_SESSION_LENGTH: 'ccu_session_length',
  CCU_SESSION_PLATFORM: 'ccu_platform_playtime_daily',
  CCU_SESSION_MODS: 'ccu_session_mods',
  CCU_PLATFORM: 'ccu_platform_unique_users',
  CCU_PEAK_OVER_TIME: 'ccu_peak_concurrent_users',
  CCU_MAX_SUM_USERS: 'ccu_max_sum_users',
  CCU_TOTAL: 'ccu_total_unique_users',
  CCU_MAX_SUM_MODS: 'ccu_max_sum_mods',
  CCU_COUNTRY_PLAYTIME: 'ccu_country_playtime',
  CCU_PLATFORM_PLAYTIME: 'ccu_platform_playtime',
  CCU_COUNTRY_ACTIVE: 'ccu_country_active',
  CCU_MODS_USERS_ACTIVE: 'ccu_mods_users_active',
}

export const UNIQUE_EVENT_TYPES = {
  ACTIVE_USER_FULL: 'active_user_full',
  ACTIVE_USER_AUTH: 'active_user_auth',
  ACTIVE_USER_INTERACTION: 'active_user_interaction',
}

export const METRICS_TYPES = {
  STANDARD: 'standard',
  UNIQUE: 'unique',
  CARDINALITY: 'cardinality',
}

export const DASHBOARD_TYPES = {
  UGC: 'ugc',
  USERS: 'users',
  HEALTH: 'health',
  MONETIZATION: 'monetization',
  MOD_UGC: 'mod_ugc',
  REVENUE: 'revenue',
  SESSION: 'session',
}

export const DASHBOARD_TAB_OPTIONS = {
  SUBSCRIPTIONS: 'subscriptions',
  MODS_UPDATED: 'mods_updated',
  API_KEY_REQUESTS: 'api_key',
  FILE_ADDED: 'file_added',
  MODS_ADDED: 'mods_added',
  DOWNLOADS: 'downloads',
  ANONYMOUS: 'anonymous',
  BANDWIDTH: 'game_bandwidth',
  COMMENTS: 'comments',
  MODS_COMMENTS: 'mods_comments',
  GUIDES_COMMENTS: 'guides_comments',
  USER_JOINED: 'user_joined',
  CREATOR_JOINED: 'creator_joined',
  ACTIVE: 'active',
  AUTH: 'auth',
  OPENED_MOD_REPORTS: 'opened_mod_reports',
  OPENED_USER_REPORTS: 'opened_user_reports',
  CLOSED_MOD_REPORTS: 'closed_mod_reports',
  CLOSED_USER_REPORTS: 'closed_user_reports',
  MODS_DELETED: 'mods_deleted',
  USERS_BANNED: 'users_banned',
  SALES: 'sales',
  REVENUE_EARNED: 'revenue_earned',
  TOKEN_PACK_SALES: 'tokens_pack_sales',
  TOKEN_PACK_REFUNDS: 'tokens_pack_refunds',
  MARKETPLACE_SALES: 'marketplace_sales',
  MARKETPLACE_REFUNDS: 'marketplace_refunds',
  CCU_SESSION_PLAYTIME: 'session_playtime',
  CCU_PLATFORM: 'ccu_platform',
  CCU_MAX_SUM_USERS: 'ccu_max_sum_users',
  CCU_PEAK_OVER_TIME: 'ccu_peak_concurrent_users',
}

export const SPARKLINE_TYPES = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
}

export const STORAGE_TYPES = {
  DAILY_SECONDARY_ONE: 'dailySecondaryOne',
  DAILY_SECONDARY_TWO: 'dailySecondaryTwo',
  WEEKLY_TOTALS: 'weeklyTotals',
  MONTHLY: 'monthly',
  DAILY: 'daily',
  TOTAL: 'total',
  GEO: 'geo',
}

export const COMPARISON_TYPES = {
  TIME_PERIOD: 'time',
  METRIC: 'metric',
}

export const REPORT_RESOURCE_FILTER = {
  GAME: 'game',
  MOD: 'mod',
  GUIDE: 'guide',
  USER: 'member',
}

export const BASE_CHART_TYPES = {
  TREND_GRAPH: 'trending graph',
  LINE: 'line',
  SPARKLINE: 'sparkline',
  COLUMN: 'column',
  SCATTER: 'scatter',
  MAP: 'map',
  VERT_SYNC: 'sync',
}

// Stats get recorded after these UTC dates
export const DATE_RECORDED = {
  USERS: '2023-02-08',
  USER_JOINED: '2023-09-04',
  BANDWIDTH: '2023-09-16',
}

export const SCATTER_CORRELATION = {
  STRONG: 'Strong',
  MODERATE: 'Moderate',
  WEAK: 'Weak',
  NONE: 'None',
}

export const PLATFORM_ORDER = [
  'Source',
  'Windows',
  'Mac',
  'Linux',
  'Android',
  'iOS',
  'Oculus',
  'Nintendo Switch',
  'PlayStation 4',
  'PlayStation 5',
  'Xbox One',
  'Xbox Series X/S',
  'Other',
]

export const AM5_LICENSE = {
  COMMON: 'AM5C427051552',
  MAP: 'AM5M427051552',
}

export const METRICS_GAME_PLATFORM_LABELS = {
  ...GAME_PLATFORM_LABELS,
  unknown: 'Other',
}

export const TIME_UNITS = {
  MONTH: 'month',
  DAY: 'day',
}

export const BIG_NUMBER_PREFIXES = [
  { number: 1e3, suffix: 'K' },
  { number: 1e6, suffix: 'M' },
  { number: 1e9, suffix: 'B' },
  { number: 1e12, suffix: 'T' },
  { number: 1e15, suffix: 'P' },
  { number: 1e18, suffix: 'E' },
]

export const METRICS_TRANSACTION_TYPES = {
  REFUNDED: 'REFUNDED',
  CLEARED: 'CLEARED',
  PAID: 'PAID',
}

export const METRICS_CURRENCY_TYPES = {
  MIO: 'MIO',
  USD: 'USD',
}

export const METRICS_DATA_TYPES = {
  NET_TOKENS: 'nettokens',
  STANDARD: 'standard',
  FILESIZE: 'filesize',
  DOLLARS: 'dollars',
  TOKENS: 'tokens',
  TIME: 'time',
}

export const METRICS_DATA_TYPES_ARRAY = Object.values(METRICS_DATA_TYPES)

export const TIME_PERIOD_SELECTOR_TYPES = {
  DROPDOWN: 'dropdown',
  DEFAULT: 'default',
  RADIO: 'radio',
}
