import { useRoute, usePermissions } from '@composables'
import { computed, unref } from 'vue'
import {
  USER_PRIVACY_EVERYONE_NO_COMMENTS,
  USER_PRIVACY_SUBSCRIBERS,
  USER_PRIVACY_EVERYONE,
  MATURITY_NONE,
} from '@config/options.js'
import {
  userSubscriptionStore,
  subscriptionStore,
  cogsGameStore,
  myTeamsStore,
  userStore,
  authStore,
  gameStore,
} from '@stores'

const UPPERCASE_UGC_NAMES = ['UGC', 'UGX']

export default function () {
  const { user: myUser, isSuperAdmin } = authStore()
  const { getPublicGame } = gameStore()
  const { cogsGame } = cogsGameStore()
  const { myGames } = myTeamsStore()
  const { getUser } = userStore()

  const { hasSubscriptionForUser: hasSubscribedToMod } = subscriptionStore()
  const { hasSubscriptionForUser: hasSubscribedToUser } =
    userSubscriptionStore()

  function ugcName(game, singular, lower) {
    return computed(() => {
      let _game = unref(game)

      if (!_game) {
        const nameId = useRoute().getGameId().value
        if (nameId) {
          _game = getPublicGame(nameId).value
          if (!_game) {
            _game = cogsGame.value
          }
        }
      }

      let _ugcName = _game?.ugc_name || 'Mods'
      if (singular) {
        _ugcName = _game?.ugc_name_single || _ugcName.replace(/s$/, '')
      }

      if (lower && !UPPERCASE_UGC_NAMES.includes(_ugcName)) {
        _ugcName = _ugcName.toLowerCase()
      }

      return _ugcName
    })
  }

  function tokenName(earnable = false) {
    return computed(() => {
      const nameId = useRoute().getGameId().value
      if (nameId && !earnable) {
        let game = getPublicGame(nameId).value
        if (!game) {
          game = myGames.value.find((g) => nameId === g.name_id)
        }
        return game.token_name || 'tokens'
      }
      return 'mio'
    })
  }

  function canViewUserProfile(userNameId, gameNameId) {
    return computed(() => {
      const user = getUser(userNameId)

      const isGameModerator =
        gameNameId &&
        usePermissions().hasPermissionForGame(gameNameId, 'moderator')

      return (
        myUser.value?.info?.name_id === userNameId ||
        isSuperAdmin() ||
        isGameModerator ||
        user.value?.privacy_options === USER_PRIVACY_EVERYONE ||
        user.value?.privacy_options === USER_PRIVACY_EVERYONE_NO_COMMENTS ||
        (user.value?.privacy_options === USER_PRIVACY_SUBSCRIBERS &&
          (hasSubscribedToUser(userNameId).value ||
            hasSubscribedToMod(userNameId).value))
      )
    })
  }

  function gameHasMatureOptions(gameNameId) {
    return computed(() => {
      const game = getPublicGame(gameNameId).value
      return game?.maturity_options > MATURITY_NONE
    })
  }

  function gameToId(gameNameId) {
    return computed(() => {
      const game = getPublicGame(gameNameId).value
      return game?.id
    })
  }

  return {
    gameHasMatureOptions,
    canViewUserProfile,
    tokenName,
    gameToId,
    ugcName,
  }
}
